@import "../styles/mixin.scss";
@import "../styles/Variable.scss";

.services-container {
  text-align: center;
  padding: 20px;
}

.services-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.services-paragraph {
  font-size: 16px;
  margin-bottom: 30px;
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-card {
  width: calc(33.33% - 20px);
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.service-card h2 {
  font-size: 20px;
  margin-bottom: 10px;
}



.service-card p {
  font-size: 16px;
}
.service-card h2:hover{
  color: #f2704d;
}

.service-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  transform: scale(1.05); 
 
}

@media (max-width: 768px) {
  .service-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .service-card {
    width: 100%;
  }
}
.services-icon{
  width: 3rem;
  border-radius: 0.3rem;
}
.services-icon1{
  width: 5rem;
  border-radius: 0.3rem;
}