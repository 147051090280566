@import "../styles/mixin.scss";
@import "../styles/Variable.scss";

/* Apply styles to the entire career page */
.career-page {
  font-family: Arial, sans-serif;
  padding: 150px 20px;
}

/* Style the career header with the large photo */
.career-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.career-photo, .career-smallimages {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.career-intro h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.career-intro p {
  font-size: 16px;
}

/* Style alternating text and image sections */
.career-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}

.career-text {
  flex: 1;
}

.career-text h2 {
  font-size: 20px;
}

.career-text ul {
  list-style-type: disc;
  margin-left: 20px;
}

.career-image {
  flex: 1;
}

.career-image img {
  border-radius: 0.8rem;
}

/* Tablet and Desktop View */
@include tablet{ // Use variable for tablet width or specify like 768px
  .career-header, .career-section {
    flex-direction: row;
    align-items: start;
  }

  .career-intro, .career-text, .career-image {
    padding: 0 15px;
  }

  .career-intro h1 {
    font-size: 28px; // Larger text for larger devices
  }

  .career-intro p, .career-text ul, .career-text h2 {
    font-size: 18px; // Slightly larger text for readability
  }
  .career-photo, .career-smallimages {
    max-width: 50%;
    
  }
}

@include desktop{// Use variable for desktop width or specify like 1024px
  .career-page {
    padding: 40px; // More padding for larger screens
  }

  .career-intro h1 {
    font-size: 32px; // Even larger headings for desktop
  }

  .career-intro, .career-text, .career-image {
    padding: 0 20px;
  }
}

/* Apply additional styles as needed */
.apply {
  color: #0056b3; // A nice clickable blue color
  text-decoration: none; // Removes the underline
  font-weight: bold; // Makes it bold

  &:hover {
    text-decoration: underline; // Underline on hover for emphasis
    color: #003580; // Darken the color slightly on hover
  }

  &:active {
    color: #002060; // Even darker when actively clicked
  }

  &:visited {
    color: #4b2e83; // A different color to indicate visited links
  }
}