.record-animation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number-animation {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
  transition: all 1s ease; /* Add a transition effect */
}
