@import "../styles/mixin.scss";
@import "../styles/Variable.scss";


.about1{
  text-align: center;
  padding: 2.5rem;
 
  .about1-head{

    @include tablet{
      font-size: 2.5rem;
    margin-bottom: 4rem;
    }
    @include desktop{
      font-size: 3rem;
      margin-bottom: 4rem;
    }
  }
}
.about {
  text-align: center;
 

  @include tablet{
  display: flex;
  flex-direction: column ;
  


  }
  @include desktop{
    display: flex;
    flex-direction: column ;
   
  
    }

  .about-main{
    @include tablet{
     display: flex;
     flex-direction:column;
     
    }

    @include desktop{
      display: flex;
     flex-direction: row;
    }

  }
  .about-mainpara{
    color: #6e737b;
    @include tablet{
      font-size:1.2rem;
      padding: 1rem;
    }
    @include desktop{
      font-size: 1.5rem;
      padding: 1.2rem;
    }
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .about-mainpic{
    width: 100%;
    border-radius: 1rem;
    margin-bottom: 2rem;

    @include tablet{
      
      width: 100%;
   
    }
    @include desktop{
      width: 50%;
    }
  }

  .about-container {
    display: flex;
    flex-direction: column;


      @include tablet {
      flex-direction:row;
     
     
      
    }
    @include desktop {
      flex-direction:row;
     
    }

    .about-types {
      display: flex;
      flex-direction: column;

        @include tablet {
       flex-direction: row;
       justify-content: space-between;
      }
      @include desktop {
        flex-direction: row;
        justify-content: space-between;
       }

      .about-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        border:1px solid rgb(211, 208, 208); 
        border-radius: 1rem;
        padding: 1rem;


        @include tablet {
          width: 25%;
        }
        @include desktop {
          width: 25%;
        }




         
       
        .about-icons {
          width:8rem;
          height:8rem;
          border-radius: 50%;
          overflow: hidden; 
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgb(211, 208, 208);
          cursor: pointer;

            @include tablet {
            width: 80px;
            height: 80px;
          }

          img {
            max-width: 100%; 
            max-height: 100%; 
            border-radius: 50%; 
            transition: transform 0.3s ease; 
          }

       
          &:hover {
            img {
              transform: scale(1.2); 
            }
          }
        
       
        }

        .about-type {
          text-align: left;

         

          h3 {
            font-size: 1.5rem;
            margin-bottom: 10px;
          }

          p {
            font-size: 1rem;
          }
        }
      }
    }

    .about-pic {
      // background-image: url('your-image-url.jpg');
      background-size: cover;
      background-position: center;
      flex-basis: 40%;
      min-height: 200px;

        @include tablet {
        min-height: 400px;
      }
    }
    
  }
}
