@import "../styles/mixin.scss";
@import "../styles/Variable.scss";

.record-container{
  display: flex;
 align-items: center;
  flex-direction: column;
}
.record-container2{
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet{
    flex-direction: row;
    width: 80%;
  }
  @include desktop{
    flex-direction: row;
    width: 80%;
  }
}
.small-desc {
  font-size: 1em;
}
.record-subhead{
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.record-sub{
  display: block;
  position: relative;
  max-width: 300px;
  max-height: 320px;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;

@include tablet{
 padding: 1%;
 height: 90%;
}
@include desktop{
padding: 2%;
margin-left: 1%;
height: 90%;
}
}
.record-sub:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #364a60, #384c6c);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}
.record-sub:hover:before {
  transform: scale(28);
}

.record-sub:hover .record-para {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.record-sub:hover .record-subhead  {
  transition: all 0.5s ease-out;
  color: #ffffff;
}




.record-img{
  width: 7rem;
  border-radius: 1rem;

  @include tablet{
    width: 4rem;
  }
}
.record-para{
  width: 90%;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;

  @include tablet{
    font-size: 0.8rem;
  }
}
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}
.record-animation {
  background-color:white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  color:#556e92;
  font-size: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.5rem;

  .record-span{
    color: #556e92;
  }
  @include tablet{
    font-size: 2rem;
  }
  @include desktop{
    font-size: 2.1rem;
  }
}
.choose_para{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}