@import "../styles/mixin.scss";
@import "../styles/fonts.scss";

.quote1-container{

  padding:0 0.4rem 1rem 0.4rem;
  background-color: white;
  padding: 4%;
  display: flex;
  justify-content: center;

}
.quote1-header{
  color:#52565d ;
  font-size: 2rem;
}
.quote1-knowme{
  display: flex;
  flex-direction: column;
  font-size: 0.6rem;
 

  @include tablet {
    font-size: 0.7rem;
    padding: 14% 1rem 0 1rem;
    width: 45%;
  
  }
  @include desktop {
    font-size: 1rem;
    padding: 0 1rem 0 1rem;
    padding: 10% 1rem 0 1rem;
    width: 50%;
    padding-right: 10%;
    
  }

}
.quote1-knowme__header{
  display: flex;
  color: #583a64;

}
.quote1-knowme__txt{
  color:#666666 ;
  font-size: 0.9rem;
}
@keyframes shine {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.quote1-first{
  display: flex;
  justify-content: center;
  
}
.quote1-second{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include tablet {
    display: flex;
    flex-direction: row;
  
  }
  @include desktop {
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    padding-right: 8% ;
  }
}


.bold{
  font-weight: 700;
  color:#666666;
}
.bold-shine{
  font-weight: 600;
}
.quote1-contactbtn{
  margin-top: 5%;
  cursor: pointer;
  padding: 1em 1.5em;
  border: none;
  border-radius: 0.31rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #583a63;
  transition: all 1000ms;
  font-size: 0.4rem;
  position: relative;
  overflow: hidden;
  outline: 2px solid #583a63;
  width: 30%;
  
  @include tablet {
    font-size: 0.8rem;
    width: 38%;
   }

   @include desktop {
    font-size: 1rem;
    width: 38%;
   }
}
.quote1-contactbtn:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #583a63;
  box-shadow: 0.25rem 0.31rem 1rem -0.25rem #583a63;
}
.quote1-contactbtn::before {
  content: "";
  position: absolute;
  left: -3rem;
  top: 0;
  width: 0;
  height: 100%;
  background-color:#583a63;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}
.quote1-contactbtn:hover::before {
  width: 250%;
}


.quote-container{
  background-color: #ffffff;
  padding: 2% 2%;
  margin-top: 8%;
  width: 100%;

  @include tablet{
    width: 40%;
  }
  @include desktop{
    width: 40%;
  }
}

.quote-heading{
  font-family:"Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color:#666666 ;
}
.quote-para{
  color: #8b959a;
  font-family:'Open Sans';
  font-weight: 700;
}
.quote-input{
  width: 96%;
  margin-top: 1rem;
  min-height: 2.5rem;
  border-color: solid #e8eaeb;



}

.quote-btn{
  z-index: 1;
  margin-top: 5%;
  cursor: pointer;
  padding: 1em 1.5em;
  border: none;
  border-radius: 0.31rem;
  font-weight: bold;
  color: white;
  transition: all 1000ms;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  outline: 2px solid white;
  width: 50%;
  min-height: 2rem;
 background-color:#e87330 ;
  
  @include tablet {
    font-size: 0.8rem;
    width: 38%;
   }

   @include desktop {
    font-size: 1rem;
    width: 38%;
   }
}
.quote-btn:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid white;
  box-shadow: 0.25rem 0.31rem 1rem -0.25rem white;
}
.quote-btn::before {
  content: "";
  position: absolute;
  left: -3rem;
  top: 0;
  width: 0;
  height: 100%;
  background-color:#ea6a1e;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}
.quote-btn:hover::before {
  width: 250%;
}