@import "../styles/mixin.scss";
@import "../styles/fonts.scss";
@import "~font-awesome/css/font-awesome.css";

.footer{
  background-color: #5b5b5b;
}
.sb__footer{
  display: flex;
  flex-direction: column;
}
.sb__footer-links{
  display: flex;
  justify-content:space-around ;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;

  @include tablet{
    flex-direction: row;
  }
  @include desktop{
    flex-direction: row;
  }
}
.sb__footer-links_div{
  width: 200px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}
.sb__footer-links_div1{
  width:300px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}
a{
  text-decoration: none;
  color: white;
}
.socialmedia{
  display: flex;
  flex-direction: row;
}
.socialmedia img{
  width: 80%;
}
.sb__footer-links_div1 h4{
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 0.9rem;
  color: #f37d17;
}
.sb__footer-links_div h4{
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 0.9rem;
  color: #f37d17;
}
.sb__footer-links_div p{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}
.sb__footer-below{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
  background-color: #757474;
}
.sb__footer-below-links{
  display: flex;
  flex-direction: row;
}
.sb__footer-below-links p{
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  color: white;
  font-weight: 600;

}
hr{
  color: white !important;
  width: 100%;

}
.sb__footer-copyright p{
  font-size: 13px;
  line-height: 15px;
  color: white;
  font-weight: 600;
}
@media screen and (max-width: 850px) {
  .sb__footer-heading h1{
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
@media screen and (max-width: 550px) {
  .sb__footer-heading h1{
    font-size: 2rem;
    line-height: 2.5rem;
  }

.sb__footer-btn p{
  font-size: 14px;
  line-height: 20px;
}
.sb__footer-below{

  flex-direction: column;
align-items: center;
}
.sb__footer-below-links{
  flex-direction: column;
}
.sb__footer-below-links p{
  margin-left: 0rem ;
  margin-top: 1rem;
}


}

@media screen and (max-width: 400px) {
  .sb__footer-heading h1{
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.icon-port{
 width: 2.5rem;
 cursor: pointer;
}
.scroll-to-top-button{
    padding: 0.5rem;
    z-index: 99999;
    background: #e87230;
    width: 2rem;
    height:2rem;
    border-radius: 4px;
    transition: all 0.4s;
    cursor: pointer;
}
.footer_yellow{
 color:  #f2ba41;
}


// modal
/* Style for the background */
.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(5px);
}

/* Style for the modal content */
.modal-content {
  position: fixed;
  background-color: #fefefe;
  padding: 1rem;
  border: 1px solid #888;
  width: 80%; /* Default to 80% for smaller screens */
  max-width: 500px; /* Max width for larger screens */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  animation-name: animatetop;
  animation-duration: 0.8s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 50%; opacity: 1}
}

/* Style for the close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .modal{
    margin: 0px;
    background-position: 0%;
  }
  .modal-content {
    width: 90%; /* More width for very small screens */
    padding: 0.5rem; /* Less padding so content has more space */
    border-radius: 1rem;
    border: none;

   
  }
  .close {
    font-size: 20px; /* Smaller close button on smaller screens */
  }

}
