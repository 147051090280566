@import "../styles/mixin.scss";
@import "../styles/Variable.scss";

.portfolio {
  text-align: center;
  padding: 20px;
}

.filter-options {
  margin-bottom: 20px;

  .port-btn {
    appearance: none;
    background-color: transparent;
    border: 0.125em solid #f2704d;
    border-radius: 0.9375em;
    box-sizing: border-box;
    color: #f2704d;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.5rem;
    font-weight: 600;
    line-height: normal;
    margin: 0.3rem;
    min-height: 1.75em;
    min-width: 0;
    outline: none;
    padding: 1em 2.3em;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;

    @include tablet{
      font-size: 0.8rem;
    }
    @include desktop{
      font-size: 0.8rem;
    }
   }
   
   .port-btn:disabled {
    pointer-events: none;
   }
   
   .port-btn:hover {
    color: #fff;
    background-color:  #f2704d;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
   }
   
   .port-btn:active {
    box-shadow: none;
    transform: translateY(0);
   }
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-gap: 20px;

  .image-item {

  
    box-sizing: border-box;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border: 1px solid #ddd;
      border-radius: 4px;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }
  }
}
