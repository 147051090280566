//Tablet display
@mixin tablet {
  @media (max-width:1280px) and (min-width: 768px){
   @content;
    
  }
}

//Desktop display
@mixin desktop {
  @media (min-width:1281px){
    @content;
  }
    
  
}

