@import "../styles/mixin.scss";
@import "../styles/Variable.scss";
// @import "../styles/fonts.scss";

.head-container{
  padding-top: 6rem;
}
.head-header{
  
   position: absolute;
   margin: 55% 3rem;
   z-index: 1;
  display: flex;
  flex-direction: column;
   
   @include tablet{
    font-size: 3rem;
    line-height: 4.25rem;
    position: absolute;
    margin: 35% 3rem;
  
   }
    @include desktop{
      font-size: 4rem;
      line-height: 4.25rem;
      position: absolute;
      margin:35% 10rem;
   
    }
}
.main-head{
  color: white;
    line-height: 2rem;
    font-weight: 700;
    font-size: 1.5rem;
   display: inline;

   @include tablet{
    font-size: 3rem;
    line-height: 4.25rem;
    
  
   }
   @include desktop{
    font-size: 4rem;
    line-height: 4.25rem;

 
  }
}
.head-image{
  padding-top: 20%;
  background-size:cover;
background-repeat: no-repeat;
height: 24rem;
max-width: 100%;
position: relative;
opacity:100%;


@include tablet{
  height: 28rem;
}
@include desktop{
  height: 45rem;
}
  
}

.btn-getstarted{
  z-index: 1;
  margin-top: 5%;
  cursor: pointer;
  padding: 1em 1.5em;
  border: none;
  border-radius: 0.31rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  transition: all 1000ms;
  font-size: 0.4rem;
  position: relative;
  overflow: hidden;
  outline: 2px solid white;
  width: 50%;
  background-color: transparent;
  
  @include tablet {
    font-size: 0.8rem;
    width: 38%;
   }

   @include desktop {
    font-size: 1rem;
    width: 38%;
   }
}
.btn-getstarted:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid white;
  box-shadow: 0.25rem 0.31rem 1rem -0.25rem white;
}
.btn-getstarted::before {
  content: "";
  position: absolute;
  left: -3rem;
  top: 0;
  width: 0;
  height: 100%;
  background-color:#ea6a1e;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}
.btn-getstarted:hover::before {
  width: 250%;
}